@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
* {
    box-sizing: border-box;

}


body {
    /* background-image: url('../assets/background-texture.jpg');
    background-repeat: repeat; */
    background-color: #9772db;
}


.navlink{
    font-family: 'Great Vibes', cursive;
    font-size: 150%;
    
    /* margin-bottom: 3%; */
}

ul{
    list-style-type: none;
}