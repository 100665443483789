.header-img{
    /* border: black double; */
    /* border-radius: 1%; */
}

nav{
    /* border: 1px red solid; */
}

.image-container{
    /* border: double; */
}

a{
    color: black;
    text-decoration: none;
    /* border: #4d4646 double; */
    border-radius: 50%;
    /* padding: 30%; */
}

a:visited{
    color: black;
    text-decoration: none;
}

.home-container{
    /* border: 1px black solid; */
}

.cover-img{
    /* border: 1px green solid; */
    /* height: 500px; */
}

.blurb{
    /* border: 1px purple solid; */
}

.bio-text{
    /* border: 1px green solid; */
}