nav ul li{
    display: inline-block;
    /* padding: 2%;
    margin: 1%; */
}

.navlink{
    /* padding: 1% 2%; */
}

.cover-img{
    /* float: right;
    margin-right: 5%; */
}

.blurb{
    /* float: left;
    width: 50%;
    padding: 5%; */
}

.shannon-img{
    /* float: right; */
}
.bio-text{
    /* float: left;
    width: 50%; */

}
