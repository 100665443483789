@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
/* purgecss start ignore */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

/* purgecss end ignore */
/* purgecss start ignore */

* {
  box-sizing: border-box;
}

body {
  /* background-image: url('../assets/background-texture.jpg');
    background-repeat: repeat; */
  background-color: #9772db;
}

.navlink{
  font-family: 'Great Vibes', cursive;
  font-size: 150%;
  /* margin-bottom: 3%; */
}

ul{
  list-style-type: none;
}

/* purgecss end ignore */
/* purgecss start ignore */

nav ul li{
  display: inline-block;
  /* padding: 2%;
    margin: 1%; */
}

.navlink{
  /* padding: 1% 2%; */
}

.cover-img{
  /* float: right;
    margin-right: 5%; */
}

.blurb{
  /* float: left;
    width: 50%;
    padding: 5%; */
}

.shannon-img{
  /* float: right; */
}

.bio-text{
  /* float: left;
    width: 50%; */
}

/* purgecss end ignore */

/* purgecss start ignore */

.header-img{
  /* border: black double; */
  /* border-radius: 1%; */
}

nav{
  /* border: 1px red solid; */
}

.image-container{
  /* border: double; */
}

a{
  color: black;
  text-decoration: none;
  /* border: #4d4646 double; */
  border-radius: 50%;
  /* padding: 30%; */
}

a:visited{
  color: black;
  text-decoration: none;
}

.home-container{
  /* border: 1px black solid; */
}

.cover-img{
  /* border: 1px green solid; */
  /* height: 500px; */
}

.blurb{
  /* border: 1px purple solid; */
}

.bio-text{
  /* border: 1px green solid; */
}

/* purgecss end ignore */
/* purgecss start ignore */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

/* purgecss end ignore */
